import React from "react";
import styles from "../../styles/main.module.css";

function Advert() {
  const scriptContent = `
  <div class="ft_async_tag">
    <noscript>
        <a href="https://servedby.flashtalking.com/click/2/250057;8718538;0;209;0/?gdpr=\${GDPR}&gdpr_consent=\${GDPR_CONSENT_78}&ft_width=300&ft_height=250&url=39818123" target="_blank">
        <img border="0" src="https://servedby.flashtalking.com/imp/2/250057;8718538;205;gif;MorningviewGR;300x250/?gdpr=\${GDPR}&gdpr_consent=\${GDPR_CONSENT_78}">
        </a>
    </noscript>
    <img src="data:image/png,ft" style="display: none" onerror='
        (function(img) {
            window.ftClick = "";
            window.ftExpTrack_8718538 = "";
            window.ftX = "";
            window.ftY = "";
            window.ftZ = "";
            window.ftOBA = 1;
            window.ftContent = "";
            window.ftCustom = "";
            window.ft300x250_OOBclickTrack = "";
            window.ftRandom = Math.random()*1000000;
            window.ftClick_8718538 = ftClick;
            if(typeof(ft_referrer)=="undefined"){window.ft_referrer=(function(){var r="";if(window==top){r=window.location.href;}else{try{r=window.parent.location.href;}catch(e){}r=(r)?r:document.referrer;}while(encodeURIComponent(r).length>1000){r=r.substring(0,r.length-1);}return r;}());}
            var ftDomain = (window==top)?"":(function(){var d=document.referrer,m=(d)?d.match("(?::q/q/)+([qw-]+(q.[qw-]+)+)(q/)?".replace(/q/g,decodeURIComponent("%"+"5C"))):"",h=(m&&m[1])?m[1]:"";return (h&&h!=location.host)?"&ft_ifb=1&ft_domain="+encodeURIComponent(h):"";}());
            var ftV_8718538={pID:"8718538",width:"300",height:"250",params:{ftx:window.ftX,fty:window.ftY,ftadz:window.ftZ,ftscw:window.ftContent,ft_custom:window.ftCustom,ft_id:window.ftID||"",ft_idEnabled:window.ftIDEnabled||"",ftOBA:window.ftOBA,ft_domain:((ftDomain||"").match(RegExp("&ft_domain=([^&$]+)","i"))||["",""])[1],ft_ifb:((ftDomain||"").match(RegExp("&ft_ifb=([^&$]+)","i"))||["",""])[1],ft_agentEnv:window.mraid||window.ormma?"1":"0",ft_referrer:encodeURIComponent(window.ft_referrer),gdpr:"\${GDPR}",gdpr_consent:"\${GDPR_CONSENT_78}",ftClick:window.ftClick,cachebuster:window.ftRandom},winVars:{ftClick_8718538:window.ftClick_8718538,ftExpTrack_8718538:window.ftExpTrack_8718538,ft300x250_OOBclickTrack:window.ft300x250_OOBclickTrack},DTimeout:1E3,GTimeout:1E3},
            ftPProc=function(d){var c=this;d=JSON.parse(JSON.stringify(d));var f=[],l=function(a,b){b="undefined"===typeof b||isNaN(b)?1:parseInt(b,10);a=a||"";for(var e=0<=b?b:0;e--;)a=encodeURIComponent(a);return a},h=function(a){a=a.constructor==Array?a:[];for(var b=0;b<a.length;b++){var e=a[b].t&&"w"==a[b].t?"winVars":"params",c=d[e][a[b].p],g=l(a[b].n||"",a[b].e),h=l(a[b].v||"",a[b].e);g=(g?g+":":"")+h;""!==g&&(c="undefined"===typeof c||""===c||"o"===a[b].m?"":c+";",d[e][a[b].p]=c+g)}a=!0;for(b=0;b<f.length;b++)if(!f[b].deployed){a=!1;break}a&&k()},k=function(){k=function(){};deployTag()};c.getVars=function(a){return JSON.parse(JSON.stringify(a?"string"===typeof a&&"undefined"!==typeof d[a]?d[a]:{}:d))};c.getSrcString=function(){var a=c.getVars("params"),b=[],e;for(e in a)a.hasOwnProperty(e)&&"undefined"!==typeof a[e]&&""!==a[e]&&b.push(e+"="+a[e]);return b.join("&")};c.deployWinVars=function(){var a=c.getVars("winVars"),b;for(b in a)a.hasOwnProperty(b)&&"undefined"!==typeof a[b]&&(window[b]=a[b])};c.addPProc=function(a){a.deployed=!1;f.push(a)};c.init=function(){d.v=5;var a=[];0===f.length?k():(f.forEach(function(b){a.push(b.id);b.timeout=parseInt(isNaN(b.timeout)?d.DTimeout:b.timeout,10);b.timer=setTimeout(function(){var a={};clearTimeout(b.timer);b.deployed||(b.deployed=!0,h(a))},b.timeout||d.DTimeout);b.deploy(c.getVars(),function(a){clearTimeout(b.timer);b.deployed||(b.deployed=!0,h(a))})}),window["ftPPIDs_"+d.pID]=d.v+";"+a.join(","))}},ft8718538PP = new ftPProc(ftV_8718538);
            
            var deployTag = function(){deployTag = function(){};clearTimeout(gTimer);ft8718538PP.deployWinVars();
                var ftTag = document.createElement("script");
                ftTag.id = "ft_servedby_8718538";
                ftTag.src = "https://servedby.flashtalking.com/imp/2/250057;8718538;201;jsappend;MorningviewGR;300x250/?"+ft8718538PP.getSrcString();
                ftTag.name = "ftscript_300x250";
                img.parentNode.insertBefore(ftTag, img.nextSibling);
            },gTimer = setTimeout(function(){clearTimeout(gTimer);deployTag();},ftV_8718538.GTimeout);ft8718538PP.init();
        })(this);
    '>
</div>`;
//   const noScriptContent = `
//   <noscript>
//     <style>
//       .no-js-message { display: block; }
//     </style>
//     <div class="no-js-message">
//       <img src="https://bs.serving-sys.com/Serving/adServer.bs?c=8&cn=display&pli=1080269343&gdpr=\${GDPR}&gdpr_consent=\${GDPR_CONSENT_68}&us_privacy=\${US_PRIVACY}&Page=&Pos=1747665938" border=0 width=300 height=250 alt="Advertisement"/>
//     </div>
//   </noscript>
// `;
  return (
    <>
      <div className={`${styles.AdvertStyleMob}`}>
        {/* <div className={`${styles.AdvertStyleThird}`}>
            <a
              href="https://bs.serving-sys.com/Serving/adServer.bs?cn=brd&ns=1&pli=1080269343&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&us_privacy=${US_PRIVACY}&Page=&Pos=1747665938"
              target="_blank"
              className="w100"
            >
              <img
                src="https://bs.serving-sys.com/Serving/adServer.bs?c=8&cn=display&pli=1080269343&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&us_privacy=${US_PRIVACY}&Page=&Pos=1747665938"
                alt="Advertisement"
                className="w-100"
              />
            </a>
            <div dangerouslySetInnerHTML={{ __html: noScriptContent }} />
        </div> */}

        {/* <div className="w-100">
          <a
            href="https://www.roche.gr/about/business/value-of-diagnostics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={`${styles.AdvertStyleThird} d-lg-flex`}></div>
          </a>
        </div> */}

        <div style={{marginBottom: 10}} dangerouslySetInnerHTML={{ __html: scriptContent }} />
        
        <div style={{marginBottom: 10}} className="w-100">
            <div className={`${styles.AdvertStyleTop} d-lg-flex`}></div>
        </div>
        
        <div className="w-100">
          <a
            href="https://www.chaniabank.gr/omilos/eteriki-kinoniki-efthyni/kinonia/digital-school/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={`${styles.AdvertStyle} d-lg-flex`}></div>
          </a>
        </div>

        
        

        
      </div>
    </>
  );
}

export default Advert;